import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path:'',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path:'admin-dashboard',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path: "order",
        loadChildren: () => import("../../admin/pages/order/order.module").then((m) => m.OrderModule),
    },
    {
        path: "transporter",
        loadChildren: () => import("../../admin/pages/transporter/transporter.module").then((m) => m.TransporterModule),
    },
    // {
    //     path: "recruiter",
    //     loadChildren: () => import("../../admin/pages/recruiter/recruiter.module").then((m) => m.RecruiterModule),
    // },
    {
        path: "calendar",
        loadChildren: () => import("../../admin/pages/calendar/calendar.module").then((m) => m.CalendarModule),
    },
    // {
    //     path: "salaried",
    //     loadChildren: () => import("../../admin/pages/salaried/salaried.module").then((m) => m.SalariedModule),
    // },
    // {
    //     path: "project",
    //     loadChildren: () => import("../../admin/pages/project/project.module").then((m) => m.ProjectModule),
    // },
    
    {
        path: "blog",
        loadChildren: () => import("../../admin/pages/blog/blog.module").then((m) => m.BlogModule),
    },
    {
        path: "parameter",
        loadChildren: () => import("../../admin/pages/parameter/parameter.module").then((m) => m.ParameterModule),
    },
    {
        path: "**",
        loadChildren: () => import("../../admin/pages/error/error.module").then((m) => m.ErrorModule),
    },
];
