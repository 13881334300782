// model-service.service.ts
import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';


@Injectable()
export class ModelBaseService<T> {
  constructor(
    @Inject(String) public resourceName: string,
    public baseService: BaseService,
    
  ) {
   
  }
  getAll(filterString?: string) {
    
    return this.baseService.getAll(`${this.resourceName}`, filterString);
  }

  getOne(id: any) {
    
    return this.baseService.getOne(`${this.resourceName}`, id);
  }


  create(item: T | any) {
    
    return this.baseService.post(`${this.resourceName}`, item);
  }

  update(id: any, item: T | any) {
    
    return this.baseService.put(`${this.resourceName}`, id, item);
  }

  createFormData(item: any) {
    
    return this.baseService.postMultipart(`${this.resourceName}`, item);
  }

  updateFormData(id: any, item: any) {
    
    return this.baseService.putMultipart(`${this.resourceName}`, id, item);
  }

  delete(id: any) {
    
    return this.baseService.delete(`${this.resourceName}`, id);
  }

  getAllPaginated(endPoint: string, params: any = {}) {
    
    const url = endPoint ? `${this.resourceName}/${endPoint}` : this.resourceName;
    return this.baseService.getAllPaginated(url, params);
  }


}
